import React from "react"
import './Bingo.css'

class Square extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  render() {
    console.log("render");

    return (
      <div className="square">
        <div className="overlayX">
          {this.state.clicked ? "X" : ""}
        </div>
        <button
          className="button"
          onClick={() => this.setState({clicked: true})}>
          {this.props.value}
        </button>
      </div>
    );
  }
}
  
  class Board extends React.Component {
    constructor(props) {
      super(props);

      let words = [
        "experience",
        "React",
        "Cloud-Native",
        "Mobile",
        "Michael",
        "Florian",
        "Jonas",
        "Case",
        "Haare",
        "Friseur",
        "Revolution",
        "Responsive",
        "Seiten",
        "Provision",
        "Stand",
        "App",
        "Affiliate",
        "Einfach",
        "Schnell",
        "Günstig",
        "Pay per Use",
        "Reichweite",
        "Elegant",
        "Skalierbar",
        "Datenbank",
      ];

      this.state = {
        "squareTiles": words.sort(() => Math.random() - 0.5)
      };
    }

    renderSquare(i) {
      return <Square value={i}/>;
    }
  
    render() {
      console.log(this.state.squareTiles);

      return (
        <div className="board">
          <div className="board-row">
            {this.renderSquare(this.state.squareTiles[0])}
            {this.renderSquare(this.state.squareTiles[1])}
            {this.renderSquare(this.state.squareTiles[2])}
            {this.renderSquare(this.state.squareTiles[3])}
            {this.renderSquare(this.state.squareTiles[4])}
          </div>
          <div className="board-row">
            {this.renderSquare(this.state.squareTiles[5])}
            {this.renderSquare(this.state.squareTiles[6])}
            {this.renderSquare(this.state.squareTiles[7])}
            {this.renderSquare(this.state.squareTiles[8])}
            {this.renderSquare(this.state.squareTiles[9])}
          </div>
          <div className="board-row">
            {this.renderSquare(this.state.squareTiles[10])}
            {this.renderSquare(this.state.squareTiles[11])}
            {this.renderSquare("Your hair, EveryWhair")}
            {this.renderSquare(this.state.squareTiles[12])}
            {this.renderSquare(this.state.squareTiles[13])}
          </div>
          <div className="board-row">
            {this.renderSquare(this.state.squareTiles[14])}
            {this.renderSquare(this.state.squareTiles[15])}
            {this.renderSquare(this.state.squareTiles[16])}
            {this.renderSquare(this.state.squareTiles[17])}
            {this.renderSquare(this.state.squareTiles[18])}
          </div>
          <div className="board-row">
            {this.renderSquare(this.state.squareTiles[19])}
            {this.renderSquare(this.state.squareTiles[20])}
            {this.renderSquare(this.state.squareTiles[21])}
            {this.renderSquare(this.state.squareTiles[22])}
            {this.renderSquare(this.state.squareTiles[23])}
          </div>
        </div>
      );
    }
  }

class Bingo extends React.Component {
    state = {
      postId: this.props.match.params.id
    }
    
    render () { 
        return (
            <div className="center">
                <h1>EveryWhone loves Bingo</h1>
                <Board />
            </div>
        );
     }
  }

export default Bingo;
