import React from 'react';
import './Navbar.css';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.menus = [
            {"name": "Startseite",
                "address": "Homepage"},
            {"name": "Neuer Termin",
                "address": "NewAppointment"},
            {"name": "Über uns",
                "address": "AboutUs"}
        ];
    }

    render() {
        return (
            <div>
                <div className="Navbar">
                        { this.menus.map((item, index) => {
                            return <div><a href={'/' + item.address}>{item.name}</a></div>;
                        })}
                </div>        
                <div className="offset" />
            </div>
        );
    }
}

export default Navbar;