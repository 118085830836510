import React from "react";
import './styles/Terminbuchung.css';

class SubmitPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "friseure": [
                "Haartz 4", 
                "Matte ab Matthes", 
                "Der Cutter", 
                "Franz Eberhard mit den Scheerenhänden"
            ],
            "URLSearchParams": new URLSearchParams(window.location.search)
        }
    }

    URLSound() {
        if(this.state.URLSearchParams.get("Select").length === 0) return false;
        if(this.state.URLSearchParams.get("Desc"  ).length === 0) return false;
        if(this.state.URLSearchParams.get("Mail"  ).length === 0) return false;
        if(this.state.URLSearchParams.get("Name"  ).length === 0) return false;
        if(this.state.URLSearchParams.get("Time"  ).length === 0) return false;
        if(this.state.URLSearchParams.get("Date"  ).length === 0) return false;
        return true;
    }

    getSuccessPage() {
    return <div className="Terminbuchung">
            <p>Vielen Dank für Ihre Buchung bei Friseur <span className="Friseur">{this.state.URLSearchParams.get("Select")}</span>.</p>
            <p>Ihr Termin findet am {(new Date(this.state.URLSearchParams.get("Date"))).toLocaleDateString("de-de")} um {this.state.URLSearchParams.get("Time")} Uhr statt.</p>
            <p>Eine Terminbestätigung per Mail wird Ihnen in den nächsten Minuten zugeschickt. Vielen Dank dass Sie EveryWhair benutzen.</p>
            <a className="Link" href="Homepage">Zurück zur Homepage</a>
        </div>
    }

    getErrorPage() {
        return <div className="Terminbuchung">
            <p>Bitte füllen Sie alle für die Terminbuchung benötigten Felder aus.</p>
            <a className="Link" href="NewAppointment">Zurück zur Terminbuchung</a><br/>
            <a className="Link" href="Homepage">Homepage</a>
        </div>
    }

    render() {
        return (
            <div>
                {this.URLSound() ? this.getSuccessPage() : this.getErrorPage()}
            </div>
        );
    }
}

export default SubmitPage;