import React from "react";
import './styles/AboutUs.css';

class AboutUs extends React.Component {
    state = {
      postId: this.props.match.params.id
    }
    
    render () { 
        return (
            <div className="leftAligned">
                <p>EveryWhair startete als kleine Idee im Kopf einer unserer Gründer - Florian Kosidovski. Von hier aus hat unser Team - Jonas Blümer, Michael Kollmann und Felix Glaser - die Idee weiterentwickelt und verfeinert.</p>
                <p>Wir alle kannten die großen Unterschiede bei der Buchung von Friseurterminen. Einige Friseure lassen Buchungen nur per Telefon zu. Friseure, welche eine Terminbuchung online anbieten, haben alle sehr unterschiedliche Eingaben. Dies wollten wir verbessern. Mit EveryWhair sollen nun alle Buchungen zentral und einheitlich machbar sein.</p>
                <p>Am 10.05.2021 haben wir uns zusammengesetzt und angefangen unser Unternehmen zu designen. Begonnen hat alles mit dem Logo. Anschließend haben wir alles andere designt. Von der Farbgebung (bzw. Farblosigkeit) zum Aufbau unseres <a href="/Bingo">Messebingos</a>.</p>
                <p>Nun, heute am 10.09.2021, sind wir endlich da und Bereit. EveryWhair wird es Ihnen vereinfachen, Ihre Schönheit aufzubessern und Ihre Haare zu pflegen.</p>
                <div className="Greet">
                    <h3>EveryWhair</h3>
                    <p>Your hair, Everywhere</p>
                </div>
            </div>
        );
     }
  }

export default AboutUs;