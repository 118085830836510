import React from "react";
import './styles/Terminbuchung.css';

class NeuerTermin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "friseure": [
                "Haartz 4", 
                "Matte ab Matthes", 
                "Der Cutter", 
                "Franz Eberhard mit den Scheeren&shy;händen"
            ],
        }
    }

    render() {
        return (
            <div className="Terminbuchung">
                <form action="/submit">
                    <div className="formHeader"><h1>Terminbuchung für Friseur: </h1>
                        <select name="Select" className="SelectElement"> {this.state.friseure.map((item, index) => {
                            return <option dangerouslySetInnerHTML={{__html: item}} />
                        })}
                        </select>
                    </div>
                    <label className="InputLabel" for="Date">Datum: </label><input className="InputElement" name="Date" type="date" /><br/>
                    <label className="InputLabel" for="Time">Uhrzeit: </label><input className="InputElement" name="Time" type="time"/><br/>
                    <label className="InputLabel" for="Name">Name: </label><input className="InputElement" name="Name" type="Name" /><br/>
                    <label className="InputLabel" for="Mail">E-Mail: </label><input className="InputElement" name="Mail" type="email" /><br/>
                    <hr/>
                    <label className="InputLabel" for="Desc">Beschreibung: </label>
                    <textarea className="InputElement" name="Desc" type="text" rows="5" cols="45"  placeholder="Bitte angeben, welche Friseurienstleistung gewünscht wird"/><br/>
                    <hr/><br/>
                    <input className="Submit" type="submit" value="Termin verbindlich buchen" />
                </form>
            </div>
        );
    }
}

export default NeuerTermin;