import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx';
import { BrowserRouter as Router, Route } from "react-router-dom"
import HomePage from './components/Homepage.jsx';
import NeuerTermin from './components/NeuerTermin.jsx';
import Bingo from './components/Bingo/Bingo.jsx';
import AboutUs from './components/AboutUs.jsx';
import SubmitPage from './components/SubmitPage.jsx';
import './index.css';

class Main extends React.Component {
  render() {
    return (
      <div className="mainReact">
        <Navbar />
        <Router>
          <Route path="/" exact component={HomePage} />
          <Route path="/Homepage/" exact component={HomePage} />
          <Route path="/NewAppointment/" exact component={NeuerTermin} />
          <Route path="/Bingo/" exact component={Bingo} />
          <Route path="/Impressum/" exact component={Bingo} />
          <Route path="/AboutUs/" exact component={AboutUs} />
          <Route path="/Submit/" exact component={SubmitPage} />
        </Router>
        <Footer />
      </div>
    );
  }
}

// ========================================

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
  