import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <div class="Footer">
                <div>
                    <p>DeliveryHairo©</p>
                </div>
                <div>
                    <p><a class="link" href="/Impressum">Impressum</a></p>
                </div>
            </div>
        );
    }
}

export default Footer;