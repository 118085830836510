import React from "react";
import './styles/Homepage.css';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postId: this.props.match.params.id,
      width: 0,
      height: 0,
    }

    this.updateDimensions = this.updateDimensions.bind(this);
  }
    

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions.bind(this));
      this.setState({
        width: window.screen.availWidth - 80,
        height: window.screen.availHeight - 400,
      });
    }

    updateDimensions() {
      console.log("Test");
      this.setState({
        width: window.screen.availWidth - 80,
        height: window.screen.availHeight - 400,
      });
    }
    
    render () { 
        return (
            <div className="center">
                <h1>EveryWhair</h1>
                <p>Your hair, everywhere</p>
                <div className="gmapDiv">
                  <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=Friseur&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
            </div>
        );
     }
  }

export default HomePage;